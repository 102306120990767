$themeColor: #3672F6;
$detailPadLeft: 0.6rem;
html, body, #root, .App,.ant-spin-nested-loading,.ant-spin-container {
    height: 100%;
    overflow: auto;
}
body {
    margin: 0;
}
.ant-spin-nested-loading >div>.ant-spin {
    max-height: none;
}
.ant-btn.ant-btn-lg {
    padding: 0.08rem 0.16rem;
    font-size: 0.13rem;
    height: unset;
    border-radius: 0.1rem;
}
.ant-btn-primary.ant-btn-background-ghost:disabled {
    color: #929292;
    border-color: #929292;
}
.ant-dropdown .ant-dropdown-menu {
    padding: 0 0.43rem 0.14rem 0.2rem;
    .ant-dropdown-menu-item {
        padding: 0.14rem 0 0 0;
        font-size: 0.13rem;
    }
    .ant-dropdown-menu-item:hover {
        background-color: unset;
        color: $themeColor;
    }
}
.ant-select-single.ant-select-lg {
    height: unset;
    .ant-select-selector {
        font-size: 0.13rem;
        color: #364053;
    }
}
.ant-select .ant-select-arrow,.ant-select-dropdown .ant-select-item {
    font-size: 0.14rem;
    color: #364053;
}
.ant-select .ant-select-clear {
    font-size: 0.14rem;
    line-height: 0.14rem;
    height: 0.14rem;
    width: unset;
    margin-top: -0.07rem;
}
.ant-select-dropdown {
    .ant-select-item {
        padding: 0.08rem 0.1rem 0.08rem 0.2rem;
        min-height: unset;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: unset;
        color: $themeColor;
    }
} 
.commonTable {
    .ant-table {
        background: none;
    }
    table {
        border-spacing: 0 6px;
    }
    .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }
    .ant-table-thead >tr{
        >th,>td {
            background: #C0D3FB;
            font-size: 0.14rem;
            font-weight: 400;
            color: #364053;
            padding: 0.16rem;
        }
    }
    .ant-table-container table>thead>tr:first-child >*:first-child {
        border-start-start-radius: 0.1rem;
        border-end-start-radius: 0.1rem;
    }
    .ant-table-container table>thead>tr:first-child >*:last-child {
        border-start-end-radius: 0.1rem;
        border-end-end-radius: 0.1rem;
    }
    .ant-table-tbody{
        cursor: pointer;
        >tr >td {
            border: none;
            padding: 0.2rem 0.16rem;
            background: #FFF;
            font-size: 0.14rem;
            color: #364053;
        }
        .ant-table-row.ant-table-row-selected{
            >.ant-table-cell {
                background: #FFF;
                border-bottom: 1px solid $themeColor;
                border-top: 1px solid $themeColor;
            }
            >.ant-table-cell:first-child {
                border-left: 1px solid $themeColor;
            }
            >.ant-table-cell:last-child {
                border-right: 1px solid $themeColor;
            }
        } 
    } 
    .ant-table-tbody>tr>td:first-child {
        border-bottom-left-radius: 0.1rem;
        border-top-left-radius: 0.1rem;
        width: 0.5rem;
    }
    .ant-table-tbody>tr>td:last-child {
        border-bottom-right-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
    }
    .ant-checkbox{
        .ant-checkbox-inner {
            border-radius: 2px;
            &::after {
                display: block;
            }
        }
    } 
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: initial;
        border: initial!important;
    }
    .stateTag {
        border-radius: 0.2rem;
        padding: 4px 12px 4px 23px;
        font-size: 0.14rem;
        position: relative;
        &::before{
            position: absolute;
            content: '.';
            line-height: 0;
            font-size: 50px;
            border-radius: 50%;
            top: -1.4px;
            left: 9px;
        }
    }
    .action {
        font-size: 0.2rem;
    }
    .ant-pagination{
        .ant-pagination-options-quick-jumper {
            color: rgba(0, 0, 0, 0.60);
        }
        .ant-pagination-item-active {
            background-color: $themeColor;
            a {
                color: #FFF;
            }
        }
    } 
    .ant-table-pagination,.ant-select-single .ant-select-selector,.ant-pagination .ant-pagination-options-quick-jumper input,.ant-typography{
        font-size: 0.14rem;
        color: #364053;
    }
}
.ant-popover-inner-content {
    .items span{
        display: block;
        cursor: pointer;
        &:first-child {
            margin-bottom: 16px;
        }
        &:hover,&:focus {
            color: $themeColor;
        }
    }
}
.ant-descriptions-item-label::after {
    content: '' !important;
}
.ant-tooltip {
    --antd-arrow-background-color: rgba(0, 0, 0, 0.60);
    .ant-tooltip-inner {
        background: rgba(0, 0, 0, 0.60);
    }
}
.confirmTip.ant-modal-confirm .ant-modal-confirm-btns {
    transform: scaleX(-1);
    text-align: left;
    .ant-btn {
        transform: scaleX(-1);
    }
}
.cancelBtn {
    border: none;
    background: #E7E7E7;
}
